<template>
    <ion-page>
        <Header-Title :position="'center'" :title="burukideName" />
        <ion-content :forceOverscroll="false">
            <div class="page__body chat">
                <div class="spin"><ion-spinner></ion-spinner></div>
                <div class="wrp-spin">
                    <div id="mst" class="chat__messages__container">
                        <ion-infinite-scroll @ionInfinite="loadNext($event)" threshold="0px" id="infinite-scroll" :disabled="isDisabled" :position="'top'">
                            <ion-infinite-scroll-content loading-spinner="dots" loading-text="Loading..."> </ion-infinite-scroll-content>
                        </ion-infinite-scroll>
                        <div class="wrp"></div>
                    </div>
                </div>
            </div>
        </ion-content>
        <ion-footer>
            <div class="chat__bar empty">
                <div class="flex">
                    <div class="bar__text">
                        <textarea id="msm" rows="1" :placeholder="$t('chat.placeholder')" @input="OnInput($event)" @click="setFocus()"></textarea>
                    </div>
                    <div class="bar__send" @click.prevent="Send()">
                        <svg class="send__icon" viewBox="0 0 24 24">
                            <path
                                d="M20.6,4.5c-0.1-0.2-0.3-0.3-0.6-0.3l-16.8,0C3,4.2,2.7,4.3,2.6,4.6C2.6,4.8,2.6,5,2.7,5.1
							c0,0.1,0.1,0.1,0.1,0.2l6.5,5.6l1.6,8.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.1,0.7-0.3l8.5-14.4C20.7,4.9,20.7,4.7,20.6,4.5L20.6,4.5
							z M9.7,10L4,5.1h14.6L9.7,10L9.7,10z M11.7,18.4l-1.4-7.5l0.1-0.1l8.7-5L11.7,18.4z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </ion-footer>
    </ion-page>
</template>
<script>
import { defineComponent, ref } from "vue";
import {
    IonPage,
    IonContent,
    IonFooter,
    useKeyboard,
    alertController,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    toastController,
    IonSpinner,
} from "@ionic/vue";

import HeaderTitle from "@/components/HeaderTitle.vue";
//import "@/assets/js/ValidationRegister/init";
//import InputInfo from "@/assets/js/InputInfo/InputInfo";
//const { InputInfo } = require("@/assets/js/InputInfo/InputInfo");
//import { Storage } from "@capacitor/storage";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { Keyboard } from "@capacitor/keyboard";

export default defineComponent({
    name: "ChatItem",
    components: {
        HeaderTitle,
        IonInfiniteScroll,
        IonInfiniteScrollContent,
        IonPage,
        IonFooter,
        IonContent,
        IonSpinner,
    },
    props: {},
    data() {
        return {
            last: "",
            textarea: "",
            firstDate: "",
            lastDate: "",
            burukideName: "",
            burukideImg: "",
            userName: "",
        };
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const isDisabled = ref(false);
        const toggleInfiniteScroll = () => {
            isDisabled.value = !isDisabled.value;
        };
        const { isOpen, keyboardHeight } = useKeyboard();
        const loadData = (ev) => {
            console.log("loaddata");
            setTimeout(() => {
                console.log("Loaded data");
                ev.target.complete();

                // App logic to determine if all data is loaded
                // and disable the infinite scroll
            }, 500);
        };
        return {
            isDisabled,
            toggleInfiniteScroll,
            router,
            route,
            isOpen,
            keyboardHeight,
            loadData,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from;
        });
    },
    ionViewWillEnter() {
        this.$el.querySelector(".page__body").classList.add("opened");
    },
    ionViewDidEnter() {
        this.$el.querySelector(".page__body").classList.remove("opened");
    },
    created() {
        Keyboard.addListener("keyboardWillShow", (info) => {
            //console.log('keyboard will show with height:', info.keyboardHeight);
            var h = this.$el.innerHeight;
            var k = h - info.keyboardHeight;
            //document.querySelector('.chat__content').style.setProperty('transform','translateY(-'+info.keyboardHeight+'px)')
            //document.querySelector('.chat__content').style.setProperty('transition', 'transform 0.3s ease');
            this.$el.querySelector(".chat__content").style.setProperty("height", k + "px");
        });
        Keyboard.addListener("keyboardDidShow", (info) => {
            //console.log('keyboard did show with height:', info.keyboardHeight);
            //document.querySelector('.chat__content').style.setProperty('transform','translateY(0px)');
            //document.querySelector('.chat__content').style.setProperty('transition', 'none');
            this.$el.querySelector(".chat__content").style.setProperty("height", "calc(100% -" + info.keyboardHeight + "px");
        });
    },
    mounted() {
        this.recive();
        this.names();
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods: {
        async names() {
            const user = await this.getStorage("user", true);
            this.userName = user.name;
            const burukides = await this.getStorage("burukideak" + this.language, true);
            var br = this.route.params.id;
            var bur = burukides.find((nombre) => nombre.id_content == br);
            //console.log(bur);
            this.burukideName = bur.title;
            this.burukideImg = bur.imgs;
        },
        async Send() {
            var that = this;
            var br = this.route.params.id;
            var message = this.$el.querySelector("#msm").value.trim();
            const user = await this.getStorage("user", true);
            const user_id = user.user_id;
            let endpoint = this.$api_host + "/user/" + user_id + "/message/";
            if (message.length > 0) {
                this.$axiosPrivate
                    .post(endpoint, {
                        lang: that.language,
                        burukideId: br,
                        message: message,
                        plataforma: "zukesan",
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            that.$el.querySelector("#msm").value = "";
                            const tempWrapper = document.createElement("div");
                            tempWrapper.classList.add("message__user");
                            var today = new Date();
                            var tday = that.lastDate;
                            var setday = today.toLocaleDateString();
                            var textday = "";
                            var datehtml = "";
                            if (!that.sameDate(tday, today)) {
                                const tempday = document.createElement("div");
                                textday = that.language == "es" ? "Hoy" : that.language == "eu"?"Gaur":"Aujourd'hui";
                                datehtml += '<div class="messages__date" data-date="' + setday + '"><span class="pretitle">' + textday + "</span></div>";
                                tempday.innerHTML = datehtml;
                                that.$el.querySelector(".chat__messages__container .wrp").appendChild(tempday);
                                that.lastDate = today;
                            }
                            var html = '<div class="message"><span class="body__m">' + message + "</span></div>";
                            tempWrapper.innerHTML = html;
                            that.$el.querySelector(".chat__messages__container .wrp").appendChild(tempWrapper);
                            that.$el.querySelector("ion-content").scrollToBottom(320);
                            //document.querySelector('.chat__messages__container').scrollTo(0,document.querySelector('.chat__messages__container').scrollHeight);
                            that.$el.querySelector("#msm").style.height = "auto";
                            that.$el.querySelector(".chat__bar").classList.add("empty");
                        }
                    })
                    .catch((error) => {
                        // console.log(error);
                        if (error.response.status === 409) {
                            this.openToastOptions(`Ha ocurrido un error`, "danger");
                        } else {
                            this.openToastOptions(error.response.data.error, "danger");
                        }
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    });
            }
        },
        async recive() {
            console.log("recive");
            var that = this;
            var br = this.route.params.id;

            const user = await this.getStorage("user", true);
            const user_id = user.user_id;
            let endpoint = this.$api_host + "/user/" + user_id + "/chat/";
            this.$el.querySelector(".chat__messages__container .wrp").innerHTML = "";
            this.$axiosPrivate
                .post(endpoint, {
                    lang: that.language,
                    burukideId: br,
                    last: that.last,
                    plataforma: "zukesan",
                })
                .then((response) => {
                    if (response.status === 200) {
                        //console.log(response);
                        if (response.data.length > 0) {
                            //console.log(response.data.length)
                            var day = "";
                            //this.$el.querySelector('.chat__messages__container .wrp').empty();
                            //forEach
                            response.data.forEach(function (e, i) {
                                var tday = new Date(e.time.replace(/\s/, "T"));
                                var setday = tday.toLocaleDateString();
                                if (i == 0) {
                                    that.last = e.id;
                                    that.firstDate = tday;
                                }
                                that.lastDate = tday;
                                var datehtml = "";

                                if (day.length == 0 || !that.sameDate(day, tday)) {
                                    const tempday = document.createElement("div");
                                    var today = new Date();
                                    var yesterday = new Date(Date.now() - 86400000);
                                    var textday = "";
                                    if (that.sameDate(tday, today)) {
                                        textday = that.language == "es" ? "Hoy" :  that.language == "eu"?"Gaur":"Aujourd'hui";
                                    } else if (that.sameDate(tday, yesterday)) {
                                        textday = that.language == "es" ? "Ayer" : that.language == "eu"?"Atzo":"Hier";
                                    } else {
                                        //textday=tday.toLocaleString('eu',{ month: 'long', day: 'numeric',  year: 'numoment.localemeric'});
                                        moment().locale(that.language);
                                        textday = moment(e.time).format("MMMM D");
                                    }
                                    datehtml += '<div class="messages__date" data-date="' + setday + '"><span class="pretitle">' + textday + "</span></div>";
                                    tempday.innerHTML = datehtml;

                                    that.$el.querySelector(".chat__messages__container .wrp").appendChild(tempday);
                                    day = tday;
                                }
                                const tempWrapper = document.createElement("div");
                                var html = "";
                                //console.log(day.toLocaleString('es',{ month: 'long', day: 'numeric',  year: 'numeric'}));
                                if (e.is_user == 1) {
                                    tempWrapper.classList.add("message__user");
                                } else {
                                    tempWrapper.classList.add("message__burukide");
                                    html += '<div class="burukide__img"><img class="ofc--img tt--ab" src="' + e.img + '"></div>';
                                }
                                html += '<div class="message"><span class="body__m">' + e.mensaje + "</span></div>";

                                tempWrapper.innerHTML = html;
                                that.$el.querySelector(".chat__messages__container .wrp").appendChild(tempWrapper);

                                that.$el
                                    .querySelector(".chat__messages__container")
                                    .scrollTo(0, that.$el.querySelector(".chat__messages__container").scrollHeight);
                            }); //fin foreach
                            that.$el.querySelector("ion-content").scrollToBottom();
                        } else {
                            console.log("no hay mensajes");
                        }
                    }
                })
                .catch((error) => {
                    // console.log(error);
                    if (error.response.status === 409) {
                        this.openToastOptions(`Ha ocurrido un error`, "danger");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                });
        },
        async loadNext(ev) {
            // console.log("next");
            var that = this;
            var br = this.route.params.id;

            const user = await this.getStorage("user", true);
            const user_id = user.user_id;
            let endpoint = this.$api_host + "/user/" + user_id + "/chat/";

            this.$axiosPrivate
                .post(endpoint, {
                    lang: that.language,
                    burukideId: br,
                    last: that.last,
                    plataforma: "zukesan",
                })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.length > 0) {
                            var day = that.firstDate;
                            //forEach
                            response.data.forEach(function (e, i, arr) {
                                that.last = e.id;
                                //} //fin if i==0
                                const tempWrapper = document.createElement("div");
                                var html = "";
                                //console.log(day.toLocaleString('es',{ month: 'long', day: 'numeric',  year: 'numeric'}));
                                if (e.id_from == user) {
                                    tempWrapper.classList.add("message__user");
                                } else {
                                    tempWrapper.classList.add("message__burukide");
                                    html += '<div class="burukide__img"><img class="ofc--img tt--ab" src="' + e.img + '"></div>';
                                }
                                html += '<div class="message"><span class="body__m">' + e.mensaje + "</span></div>";

                                tempWrapper.innerHTML = html;
                                that.$el.querySelector(".chat__messages__container .wrp").prepend(tempWrapper);

                                var tday = new Date(e.time.replace(/\s/, "T"));
                                var fD = new Date(that.firstDate);
                                var datehtml = "";
                                var dt = that.firstDate.toLocaleDateString();
                                //console.log(that.firstDate);
                                //var today=new Date();
                                //var yesterday = new Date(Date.now() - 86400000);
                                var dt2 = tday.toLocaleDateString();
                                if (day.length != 0) {
                                    //console.log(tday.toLocaleDateString())
                                    if (that.sameDate(fD, tday)) {
                                        if (document.querySelector('.messages__date[data-date="' + dt + '"]') != null) {
                                            that.$el.querySelector('.messages__date[data-date="' + dt + '"]').remove();
                                        }
                                    }

                                    const tempday = document.createElement("div");

                                    var textday = "";

                                    moment().locale(that.language);
                                    textday = moment(e.time).format("MMMM D");

                                    datehtml += '<div class="messages__date" data-date="' + dt2 + '"><span class="pretitle">' + textday + "</span></div>";
                                    tempday.innerHTML = datehtml;

                                    that.$el.querySelector(".chat__messages__container .wrp").prepend(tempday);
                                    that.firstDate = tday;
                                }
                                if (i == arr.length - 1) {
                                    /*
                                const tempday2=document.createElement('div');
                                var textday2='';
                                if(that.sameDate(tday, today)){
                                    textday2=(that.language=='es')?'Hoy':'Gaur';
                                }else if(that.sameDate(tday, yesterday)){
                                    textday2=(that.language=='es')?'Ayer':'Atzo';
                                }else{
                                    textday2=day.toLocaleString('es',{ month: 'long', day: 'numeric',  year: 'numeric'});
                                }

                                datehtml+='<div class="messages__date"><span class="pretitle">'+textday2+'</span></div>';
                                tempday2.innerHTML = datehtml;

                                document.querySelector('.chat__messages__container .wrp').prepend(tempday2);
                                that.firstDay=tday;*/
                                }

                                //document.querySelector('.chat__messages__container').scrollTo(0,document.querySelector('.chat__messages__container').scrollHeight);
                            }); //fin foreach
                            ev.target.complete();
                        } else {
                            ev.target.disabled = true;
                            //var mgs = (that.language=='es')?'No hay más mensajes':'Ez dago';
                            //that.openToast(mgs);
                        }
                    }
                })
                .catch((error) => {
                    // console.log(error);
                    if (error.response.status === 409) {
                        this.openToastOptions(`Ha ocurrido un error`, "danger");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                });
        },
        async presentAlert() {
            const alert = await alertController.create({
                cssClass: "my-custom-class",
                header: "ERROR",
                subHeader: "Lo sentimos ha ocurrido un error",
                message: "Error de Red no se ha podido conectar con el servidor.",
                buttons: ["OK"],
            });
            await alert.present();
            const { role } = await alert.onDidDismiss();
            //this.$router.push("/" + this.language+"/onboardinguser");
            console.log("onDidDismiss resolved with role", role);
        },
        OnInput(arg) {
            arg.target.style.height = "auto";
            arg.target.style.height = arg.target.scrollHeight + "px";
            if (this.textarea != arg.target.scrollHeight) {
                //document.querySelector('.chat__messages__container').scrollTo(0,document.querySelector('.chat__messages__container').scrollHeight);
                var ht = this.$el.querySelector("#mst").offsetHeight;

                this.$el.querySelector("#mst").style.height = parseInt(ht - arg.target.scrollHeight) + "px";
                this.$el.querySelector("ion-content").scrollToBottom();

                this.textarea = arg.target.scrollHeight;
            }
            if (arg.target.value == "") {
                this.$el.querySelector(".chat__bar").classList.add("empty");
            } else {
                this.$el.querySelector(".chat__bar").classList.remove("empty");
            }
        },
        sameDate(first, second) {
            if (first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate()) {
                return true;
            }
            return false;
        },
        setFocus() {
            this.$el.querySelector("ion-content").scrollToBottom();
        },
        async openToast(mgs) {
            const toast = await toastController.create({
                message: mgs,
                duration: 2000,
            });
            return toast.present();
        },
    },
});
</script>
