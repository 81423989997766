<template>
    <ion-page>
        <Header-Start />
			<ion-content :fullscreen="true" :forceOverscroll="false">
				<div class="recovery__page">
					<div class="form__content form__content--fixed">
						<div class="wrp flex direction--column">
							<div class="form__desktop__header">
								<img src="../assets/img/logo-zukesan.svg" />
							</div>
							<div class="recovery__intro">
								<div class="section__header">
									<h1 class="section__title title__l text--center">{{ $t('recovery.title') }}</h1>
								</div>
								<div class="text">
									<p class="body__s text--center" v-html="$t('recovery.text1')"></p>
								</div>
							</div>
								<form id="formRecovery" name="recovery" class="form form--recovery form--fixed flex__wrp direction--column" action="." method="post" autocomplete>
								<fieldset>
									<sd-form-input type="email" id="recoveryEmail" name="email" :placeholder="$t('forms.fields.email')" autocomplete="username" />
									<p class="form__message"></p>
									<sd-form-input type="hidden" name="lang" :value="language"></sd-form-input>
								</fieldset>
								<div class="form__actions">
									<sd-button modifiers="base" submit name="btnSubmit">{{ $t("forms.recovery.cta") }}</sd-button>
									<router-link class="btn btn--secondary" v-bind:to="'/' + this.language + '/login'">
									{{$t("forms.goto.login.text")}}{{$t("forms.goto.login.cta")}}
									</router-link>
								</div>
							</form>
						</div>
					</div>
				</div>
			</ion-content>
    </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonContent, IonPage, loadingController, modalController } from "@ionic/vue";

import HeaderStart from "@/components/HeaderStart.vue";
import SdButton from "@/components/Button.vue";
import SdFormInput from "@/components/FormInput.vue";

import SMSCodeVerification from "@/views/SMSCodeVerification.vue";
// import PasswordChange from "@/views/PasswordChange.vue";

import Validation from "@/assets/js/Validation/Validation";

export default defineComponent({
    name: "PasswordRecovery",
    components: {
        IonContent,
        IonPage,
        HeaderStart,
        SdButton,
        SdFormInput,
    },
    data() {
        return {
            ionRouter: null,
            modal: null,
            modalOpen: false,
        };
    },
    mounted() {
        const _Recovery = this.form = this.$el.querySelectorAll(".form--recovery")[0];
        const _Constraints = {
            "email": {
                presence: {
                    message: "^" + this.$t("forms.validate.email.required"),
                },
                email: {
                    message: "^" + this.$t("forms.validate.email.format"),
                },
            },
        };

        new Validation(_Recovery, _Constraints, {
            onFormSubmit: () => {
                this.formData = JSON.parse(this.serializeJSON(_Recovery[0]));
                this.openModal(SMSCodeVerification, { email: this.formData.email });
            }
        });
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    methods: {
        async openModal(component, data) {
            let componentProps = {
                closeMe: this.closeModal,
                passDataBack: this.passDataBackModal,
            };
            if (data) componentProps.passData = data;
            this.modal = await modalController.create({
                component: component,
                keyboardClose: false,
                componentProps: componentProps,
            });
            // FirebaseAnalytics.logEvent({
            //     name: "modal_open",
            //     params: {
            //         name: "invitations",
            //     },
            // });
            return this.modal.present();
        },
        passDataBackModal(data) {
            if (data.verification) {
                this.$router.push({ name: 'PasswordChange', params: { email: this.formData.email, user_id: data.user_id, access_token: data.access_token } });
            }
        },
        closeModal() {
            let args = Array.prototype.slice.call(arguments);
            if (args[0] === true) {
                this.closeToast();
            }
            if (this.modal) {
                this.modal.dismiss().then(() => {
                    this.modal = null;
                    this.modalOpen = false;
                });
            }
        },
        async saveFormData(data) {
            console.log(data);
            const loading = await loadingController.create({
                //cssClass: "my-custom-class",
                message: this.$t("registration.saving"),
                duration: this.timeout,
            });

            await loading.present();

        //     let endpoint = this.$api_host + "/user/register/";
        //     this.$axiosPrivate.post(endpoint, data, {
        //         headers: {
        //             // 'Content-Type': 'application/json'
        //             // 'Content-Type': 'application/x-www-form-urlencoded'
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     }).then((response) => {
        //         // console.log(response.data)
        //         let resp = response.data;
        //
        //         let user = {
        //             "user_id": resp.user_id,
        //             "email": data.email,
        //             "name": data.name,
        //             "last_name": data.last_name,
        //             "last_name2": data.last_name2,
        //             "phone_prefix": data.phone_prefix,
        //             "phone_number": data.phone_number,
        //             "postal_code": data.postal_code,
        //             "country": data.country,
        //             "is_local": data.is_local,
        //             "reference": data.reference,
        //             "birth_date": data.birth_date,
        //             "terms_version": data.terms_version,
        //         };
        //
        //         this.setStorage("user", user, true);
        //         this.setStorage("access_token", resp.access_token, true);
        //         this.setStorage("access_token_expires", resp.access_token_expires, true);
        //
        //         loading.dismiss();
        //         // console.log("Logged => Go to dashboard");
        //         this.$router.push("/" + this.language + "/onboardinguser")
        //     })
        //     .catch((error) => {
        //         loading.dismiss();
        //         if (error.response.status === 409) {
        //             this.openToastOptions(`El usuario ${data.email} ya está registrado`, "danger");
        //         } else {
        //             this.openToastOptions(error.response.data.error, "danger");
        //         }
        //         // console.log(error.response.data);
        //         // console.log(error.response.status);
        //         // console.log(error.response.headers);
        //     });
        },
    },
});
</script>
