<template>
	<div class="social__controls flex__wrp">
		<div class="flex">
			<div v-if="txalosControl" class="social__like">&nbsp;
				<div class="like__icon" v-bind:class="{ 'txalok' : status}" @click.prevent="txalos($event)">
					<img class="clap tt--ab" src='../assets/icon/clap.svg' >
					<img class="clapped tt--ab" src='../assets/icon/clapped.svg'>
				</div>
				<div class="like__number body__xs" v-if="txalosN"><span v-if="txalosN > 0">{{txalosN}}</span></div>
                <div class="like__number body__xs" v-else><span v-if="txalosNumber > 0">{{txalosNumber}}</span></div>
			</div>
			<div v-if="thumbs" class="social__burukideak">
				<div v-bind:key="img" v-for="(img) in imgs">
					<div class="social__participants">
						<img class="ofc--img tt--ab" v-bind:src="img">
					</div>
				</div>
			</div>
			<div v-if="dateControl" class="social__date">
				<div class="date body__s">{{date}}</div>
			</div>
		</div>
		<div v-if="shareButtonControl" class="social__share">
			<div class="share__icon" @click.prevent="sharebar"><img src='../assets/icon/share.svg'></div>
		</div>

	</div>
</template>
<script>
import { toastController, alertController } from '@ionic/vue';
import { Share } from '@capacitor/share';
export default {
    name: "SharebarItem",
    components: {

    },
    props: {
        txalosControl:{
            type: Boolean,
            default:true,
        },
        txalosNumber:String,
        thumbs:{
            type: Boolean,
            default:true,
        },
        dateControl:{
            type: Boolean,
            default:false,
        },
        date:String,
        shareButtonControl:{
            type: Boolean,
            default:true,
        },
        imgs:Object,
		id:String,
        url:String
    },
    data() {
        return {
			txalosN:this.txalosNumber,
            status:false,
        };
    },
    created() {

    },
    mounted() {
        this.checkTxalos();
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        }
    },
    methods: {
		async txalos(arg) {
            const access_token = await this.getStorage("access_token", true);
            const user = await this.getStorage("user", true);
            const user_id = user.user_id;
            let endpoint = this.$api_host + "/user/" + user_id + "/likes";

            this.$axiosPrivate.post(endpoint, {
                    id: this.id,
                    check: false,
                }, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.txalosN = response.data.likes;
                        this.status = (response.data.status == 0 || response.data.status == null)?false:true;
                        if (arg) {
                            if (this.status == 0) {
                                arg.target.parentNode.classList.remove('txalok');
                            } else {
                                arg.target.parentNode.classList.add('txalok');
                            }
                        }
                    }
                }).catch((error) => {
                    if (error.response.status === 409) {
                        this.openToastOptions(`Ha ocurrido un error`, "danger");
                    } else if (error.response.status !== 401) {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                });
		},
        async checkTxalos(){
            // var that = this;
            const user = await this.getStorage("user", true);
            const user_id = user.user_id;
            let endpoint = this.$api_host + "/user/" + user_id + "/likes";
            this.$axiosPrivate
                .post(endpoint, {
                    id: this.id,
                    check: true
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.txalosN = response.data.likes;
                        this.status = (response.data.status == 0 || response.data.status == null)?false:true;
                    }
                }).catch((error) => {
                    if (error.response.status === 409) {
                        this.openToastOptions(`Ha ocurrido un error`, "danger");
                    } else if (error.response.status !== 401) {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                });
        },
		async openToast() {
			const toast = await toastController
			.create({
				message: 'txalo +1',
				duration: 2000
			})
			return toast.present();
		},
		async presentAlert(mgs) {
			const alert = await alertController
			.create({
				cssClass: 'my-custom-class',
				header: 'ERROR',
				subHeader: 'Lo sentimos ha ocurrido un error',
				message: mgs,
				buttons: ['OK'],
			});
			await alert.present();
			const { role } = await alert.onDidDismiss();
			console.log('onDidDismiss resolved with role', role);
		},
		sharebar(){
            var text = (this.language=='es')?'Contenido interesante de la App ZukEsan.':'ZukEsan App-eko eduki interesgarria';
			Share.share({
				title: 'See cool stuff',
				text: text,
				url:this.url,
				dialogTitle: 'Share with buddies',
			});
		}
	}
};
</script>
