<template>
    <ion-page>
        <Header-Title :position="'center'" :title="$t('categories.albisteak')" :principal="true"/>
        <ion-content :fullscreen="true" :forceOverscroll="false">
			<ion-refresher slot="fixed" @ionRefresh="this.actNoticias($event)" closeDuration="360ms" pullFactor="0.8">
				<ion-refresher-content v-if="isIos" pulling-icon="Default"></ion-refresher-content>
				<ion-refresher-content v-else ></ion-refresher-content>
			</ion-refresher>
			<div class="page__body pnoticias">
				<div class="spin"><ion-spinner></ion-spinner></div>
				<div class="wrp wrp-spin">
					<div class="area__container">
						<div class="featured__new" v-bind:key="noticia" v-for="(noticia) in this.noticias">
							<NewExcerpt :noti="noticia"/>
						</div>
					</div>
				</div>
				<!-- fin wrp -->
				<div class="no__event flex__wrp align--center" style="opacity:0" v-if="this.noticias.length == 0">
					<div class="text--center">
						<h2 class="no__event__title title__l">{{ $t("albisteak.noevents.title") }}</h2>
						<p class="no__event__text body__m">{{ $t("albisteak.noevents.text") }}</p>
					</div>
				</div>
				<!-- fin no-events -->
			</div><!-- fin page__body -->
			<ion-infinite-scroll @ionInfinite="loadNext($event)" id="infinite-scroll" :disabled="isDisabled" threshold="300px">
			<ion-infinite-scroll-content >
			</ion-infinite-scroll-content>
			</ion-infinite-scroll>
        </ion-content>
        <Galdetu-Float/>
    </ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";
import { IonContent, IonPage, IonRefresher, alertController, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent, isPlatform, IonSpinner} from "@ionic/vue";

import HeaderTitle from "@/components/HeaderTitle.vue";
import GaldetuFloat from "@/components/GaldetuFloat.vue";
import NewExcerpt from "@/components/NewExcerpt.vue";
//import Sdmenu from "@/components/menu.vue";

import { chevronDownCircleOutline } from 'ionicons/icons';

// import "@/assets/js/ValidationRegister/init";
// import InputInfo from "@/assets/js/InputInfo/InputInfo";
// const { InputInfo } = require("@/assets/js/InputInfo/InputInfo");
// import { Storage } from "@capacitor/storage";
//import axios from "axios";

export default defineComponent({
    name: "newsItem",
    components: {
	IonContent,
	IonPage,
	HeaderTitle,
	//Sdmenu,
	GaldetuFloat,
	NewExcerpt,
	IonRefresher,
	IonRefresherContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
  IonSpinner


    },
    props:{

    },
    data(){
	return{
	from: null,
	noticias:[],
    last:null,
    timestamp:null,
	}
    },

    setup() {
    const data = ref();
    const isDisabled = ref(false);
    const toggleInfiniteScroll = () => {
      isDisabled.value = !isDisabled.value;
    }


	return { chevronDownCircleOutline, isDisabled, toggleInfiniteScroll,data}

    },
    beforeRouteEnter(to, from, next) {
        //document.querySelectorAll('ion-content').forEach(function(e){e.scrollToTop()});
	next((vm) => {
	vm.from = from;
	});
    },
    ionViewWillEnter() {
     this.$el.querySelector('.page__body').classList.add('opened')
    },
    ionViewDidEnter() {

    },
    ionViewDidLeave(){
      if(this.$el.querySelector('.wrp-spin')){
      this.$el.querySelector('.wrp-spin').classList.remove('wrp-spin');
    }
      if(this.$el.querySelector('.spin')){
        this.$el.querySelector('.spin').remove();
      }
    },
    async created() {

        //this.noticias = await this.getStorage("noticias", true);
	},
    mounted() {
        this.connect();

		},
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
        isAndroid() {
            return isPlatform("android");
        },
        isIos() {
            return isPlatform("ios");
        }
    },
    methods:{
		Next(){
			this.$router.push("/" + this.language+"/codigo");
		},
		galdetu(){
		},
		newTemplate(arg){

			this.$router.push("/" + this.language+"/newtemplate?id="+arg);
		},
        async connect(arg){
            var that = this;
            if(arg){
                that.saveData('timestampNoticias'+this.$i18n.locale,'')
            } //si arg=true lo actualizamos
            console.log('connect');
            //const access_token = await this.getStorage("access_token", true);
            //const user = await this.getStorage("user", true);
            //const user_id=user.user_id;
            this.timestamp = await this.getStorage("timestampNoticias"+this.$i18n.locale, true);
            let endpoint = this.$api_host + "/noticias/list/?lang=" + this.$i18n.locale + "&limit=4";

            if(this.timestamp ==null || parseInt(this.timestamp)+(1440000) < Date.now() || this.timestamp == ''){

                that.$axiosPrivate.get(endpoint
                ).then((response) => {
                    if (response.status === 200) {
                        that.noticias = (response.data.length >0)?response.data:'';
                        that.saveData('noticias'+this.$i18n.locale, response.data);
                        that.saveData('timestampNoticias'+this.$i18n.locale, Date.now());
                        that.data=true;
                        if(response.data.length > 0){

                            that.last=that.noticias[that.noticias.length -1].id_content;
                            that.saveData('lastNoticia', that.noticias[that.noticias.length -1].id_content);
                        }else{
                          that.$el.querySelector('.no__event').style.opacity=1;
                        }
                        this.$el.querySelector('.page__body').classList.remove('opened');
                    }
                }).catch((error) => {

                    if (error.response.status === 409) {
                        this.openToastOptions(`Ha ocurrido un error`, "danger");
                    } else {
                        this.openToastOptions(error.response.data.error, "danger");
                    }

                });

            }else{
                that.loadData();
                that.data=true;


            }

        },
        async saveData(key, data) {
            this.setStorage(key, data, true);
        },
        async loadData() {
            this.noticias = await this.getStorage("noticias"+this.$i18n.locale, true);
            this.$el.querySelector('.no__event').style.opacity=1;
            this.$el.querySelector('.page__body').classList.remove('opened');
        },
        async presentAlert() {
			const alert = await alertController
			.create({
				cssClass: 'my-custom-class',
				header: 'ERROR',
				subHeader: 'Lo sentimos ha ocurrido un error',
				message: 'Error de Red no se ha podido conectar con el servidor.',
				buttons: ['OK'],
			});
			await alert.present();
			const { role } = await alert.onDidDismiss();
			console.log('onDidDismiss resolved with role', role);
		},
        async actNoticias(event) {
            await this.connect(true);
            event.target.complete();

        },
        async loadNext(ev){
            console.log('next');
            ev.target.disabled = true;
            var that=this;
            var last  = await this.getStorage("lastNoticia", true);
            let endpoint = this.$api_host + "/noticias/list/?lang=" + this.$i18n.locale + "&limit=4&last="+last;
            that.$axiosPrivate.get(endpoint
            ).then((response) => {
                if (response.status === 200) {
                    if(response.data.length == 0){
                        console.log('No hay más noticias destacadas');
                    }
                    response.data.forEach(function(i){
                        that.noticias.push(i);
                    })

                    that.saveData('noticias', that.noticias);
                    that.saveData('timestampNoticias', Date.now());

                    that.last=that.noticias[that.noticias.length -1].id_content;
                    that.saveData('lastNoticia', that.noticias[that.noticias.length -1].id_content);

                    ev.target.disabled = false;
                }
            }).catch((error) => {

                if (error.response.status === 409) {
                    this.openToastOptions(`Ha ocurrido un error`, "danger");
                } else {
                    this.openToastOptions(error.response.data.error, "danger");
                }

            });
        }


	}
});
</script>
