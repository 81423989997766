<template>

	<div :class="'page__header__logo' + (iso ? ' page__header__logo--iso' : '')">
		<img src="../assets/img/logo-zukesan.svg" />
	</div>
   
</template>

<script>
export default {
    name: "HeaderLogo",
    props: {
        iso: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
