<template>
	<div>
		<div class="page__header title--header bg--header border--header principal">
			<div class="header__title">
				<div>{{$t("general.profila")}}</div>
			</div>
		</div>
		<div class="page__body">
			<div class="wrp" >
				<div class="area__container">
					<div class="user__profile flex">
						<div class="user__img">
							<img src='../assets/icon/menu-user.svg'>
						</div>
						<div>
							<div class="user__name title__m">{{ userData.name }} {{ userData.last_name }} {{ userData.last_name2 }}</div>
							<div class="user__email">{{ userData.email }}</div>
						</div>
					</div>
					<div class="sp--line"></div>
					<div class="user__messages">
						<div class="featured__message" v-bind:key="mgs" v-for="(mgs) in this.mensajes">
							<ProfileExcerpt :mgs="mgs"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent } from "vue";
//import { IonContent} from "@ionic/vue";
//import HeaderTitle from "@/components/HeaderTitle.vue";
//import GaldetuFloat from "@/components/GaldetuFloat.vue";
import ProfileExcerpt from "@/components/ProfileExcerpt.vue";
//import "@/assets/js/ValidationRegister/init";
//import InputInfo from "@/assets/js/InputInfo/InputInfo";
//const { InputInfo } = require("@/assets/js/InputInfo/InputInfo");
export default defineComponent({
	name: "ProfileDItem",
	components: {
		//IonContent,
		//HeaderTitle,
		ProfileExcerpt
	},
	data() {
		return {
			auth: this.$auth.isAuthenticated,
			userData: {},
			from: null,
			mensajes:[]
		}
	},
	watch:{
		// $route(to, from) {
        $route() {
			// console.log('watch');
			// console.log(to);
			// console.log(from);
			this.show = false;
			if (this.$auth.isAuthenticated) {
				this.connect();
			}
		}
	},
	mounted() {
        if (this.$auth.isAuthenticated) {
            this.connect();
        }
	},
	computed: {
		language() {
			return this.$i18n.locale || document.documentElement.lang;
		}
	},
	methods:{
        async connect() {
            if (this.auth) {
                this.userData = await this.getStorage("user", true);
                // const user_id = this.userData.user_id;
                // const user = await this.getStorage("user", true);
                // const user_id = user.user_id;
                let endpoint = `${this.$api_host}/user/${this.userData.user_id}/conversations`;
                this.$axiosPrivate
                    .get(endpoint)
                    .then((response) => {
                        if (response.status === 200) {
                            this.mensajes = response.data;
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 409) {
                            this.openToastOptions(`Ha ocurrido un error`, "danger");
                        } else if (error.response.status !== 401) {
                            this.openToastOptions(error.response.data.error, "danger");
                        }
                    });
			}
        },
        async saveData(key, data) {
            this.setStorage(key, data, true);
        },
        async loadData() {
            this.destacados = await this.getStorage("destacados", true);
        },
	}
});
</script>