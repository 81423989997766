<template>
	<ion-page>
		<Header-Title :position="'center'" :title="$t('general.profila')" :principal="true" />
		<ion-content :fullscreen="true" :forceOverscroll="false">
			<div class="page__body">
				<div class="wrp">
					<div class="area__container">
						<div class="user__profile flex align--center">
							<div class="user__img">
								<img src='../assets/icon/menu-user.svg'>
							</div>
							<div>
								<div class="user__name title__l">{{ userData.name }} {{ userData.last_name }} {{ userData.last_name2 }}</div>
								<div class="user__email">{{ userData.email }}</div>
							</div>
						</div>
						<div class="sp--line"></div>
						<div class="user__messages">
							<div class="featured__message" v-bind:key="mgs" v-for="(mgs) in this.mensajes">
								<ProfileExcerpt :mgs="mgs"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ion-content>
		<Galdetu-Float/>
	</ion-page>
</template>
<script>
import { defineComponent } from "vue";
import { IonContent, IonPage} from "@ionic/vue";
import HeaderTitle from "@/components/HeaderTitle.vue";
import GaldetuFloat from "@/components/GaldetuFloat.vue";
import ProfileExcerpt from "@/components/ProfileExcerpt.vue";
export default defineComponent({
	name: "ProfileItem",
	components: {
		IonContent,
		HeaderTitle,
		IonPage,
		GaldetuFloat,
		ProfileExcerpt
	},
	props:{
	},
	data() {
		return {
            userData: {},
			from: null,
			mensajes:[]
		}
	},
	setup() {
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.from = from;
		});
	},
	// async created() {
    //     this.userData = await this.getStorage("user", true);
	// },
	mounted() {
		this.connect()
	},
	computed: {
		language() {
			return this.$i18n.locale || document.documentElement.lang;
		}
	},
	methods:{
        async connect() {
            this.userData = await this.getStorage("user", true);
            let endpoint = `${this.$api_host}/user/${this.userData.user_id}/conversations`;
            // const user = await this.getStorage("user", true);
            // const user_id=user.user_id;
            // let endpoint = this.$api_host + "/user/" + user_id + "/conversations";
            this.$axiosPrivate
                .get(endpoint)
                .then((response) => {
                    if (response.status === 200) {
                        this.mensajes = response.data;
                    }
                })
                .catch((error) => {
                    if (error.response.status === 409) {
                        this.openToastOptions("Ha ocurrido un error", "danger");
                    } else if (error.response.status !== 401) {
                        this.openToastOptions(error.response.data.error, "danger");
                    }
                });
        },
        async saveData(key, data) {
            this.setStorage(key, data, true);
        },
        async loadData() {
            this.destacados = await this.getStorage("destacados", true);
        },
	}
});
</script>
