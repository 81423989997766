// import createAuth0Client from "@auth0/auth0-spa-js";
import { computed, watchEffect } from "vue";
// import { Plugins } from "@capacitor/core";
// import VueCookies from 'vue-cookies';
import axios from "axios";
import Cookies from "js-cookie";
import { store } from '../store/';
import { Storage } from "@capacitor/storage";

let app_prefix = process.env.VUE_APP_STORAGE_PREFIX;
let api_host = process.env.VUE_APP_API_HOST;
let lang;
// let moment;

const authPlugin = {
    isAuthenticated: computed(() => store.isAuthenticated),
    loading: computed(() => store.loading),
    user: computed(() => store.user),
    logout,
};

async function setStorage(key, value) {
    await Storage.set({ key: key, value: JSON.stringify(value) });
}

async function getStorage(key) {
    const item = await Storage.get({ key: key });
    return JSON.parse(item.value);
}

async function removeStorage(key) {
    await Storage.remove({ key: key });
}

async function clearLocalStorage(keyPrefix) {
    const StorageKeys = await Storage.keys();
    StorageKeys["keys"].forEach((key) => {
        if (key.startsWith(keyPrefix)) {
            removeStorage(key);
        }
    });
}

function setUserLogged(userLogged) {
    // Cookies.set("userLogged", userLogged, { expires: 30 });
    Cookies.set("userLogged", JSON.stringify(userLogged));
}

function deleteUserLogged() {
    Cookies.remove('userLogged');
}

function getUserLogged() {
    const userLogged = Cookies.get("userLogged");
    return userLogged ? JSON.parse(userLogged) : null;
}

async function tokenCheck() {
    store.loading = true;
    const access_token = await getStorage(`${app_prefix}access_token`);
    const access_token_expires = await getStorage(`${app_prefix}access_token_expires`);
    const user = await getStorage(`${app_prefix}user`);


    return new Promise((resolve) => {

        if (access_token && (access_token_expires > Math.round((new Date()).getTime() / 1000))) {
            if (!getUserLogged()) {
                let endpoint = api_host + "/user/" + user.user_id + "/get/";
                axios.get(endpoint, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        AppName: store.appName,
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        store.user = response.data;
                        store.loading = false;

                        setUserLogged(response.data);
                        setStorage(`${app_prefix}user`, response.data);
                        resolve(true);
                    }

                }).catch((error) => {
                    //console.log(error);
                    if (error.response.status === 401) {
                        store.loading = false;

                        clearLocalStorage(app_prefix);
                        resolve(false);
                    }
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                });
            } else {
                // console.log(getUserLogged());
                resolve(true);
            }
        }
        else {
            deleteUserLogged();
            clearLocalStorage(app_prefix);
            resolve(false);
        }
    });
}

function logout(targetUrl) {
    deleteUserLogged();
    clearLocalStorage(app_prefix);
    window.location.replace(targetUrl);
}

export const routeGuard = (to, from, next) => {
    // const { Browser } = Plugins;
    // const { isAuthenticated, loading, loginWithRedirect } = authPlugin;
    const { isAuthenticated, loading } = authPlugin;

    const verify = () => {
        tokenCheck().then((isAuthenticated) => {
            store.isAuthenticated = isAuthenticated;
            if (!isAuthenticated) {
                lang = to.params.lang;
                window.location.replace("/" + lang + "/login");
            } else {
                console.log(to);
                return next();
            }
        });
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!loading.value) {
        return verify();
    }

    // Watch for the loading property to change before we check isAuthenticated
    watchEffect(() => {
        console.log("Redirect to Login", loading, isAuthenticated);
        //if (loading.value === false) {
            return verify();
        //}
    });
};
export const routeGuard2 = (to, from, next) => {
/*
    if(from){
      lang = to.params.lang;
      window.location.replace("/" + lang + "/login");
    }*/
    return next();
};


export const setupAuth = async (options, callbackRedirect) => {
    console.log(options, callbackRedirect);

//     client = await createAuth0Client(options);
//
//     loginRedirect(callbackRedirect);

    store.isAuthenticated = await tokenCheck();

    return {
        install: (app) => {
            // moment = app.config.globalProperties.$moment;
            app.config.globalProperties.$auth = authPlugin;
            app_prefix = app.config.globalProperties.$app_prefix;
        },
    };
};
