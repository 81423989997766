<template>
    <div :class="localClass">
        <label :for="localID">
            <input type="radio" :id="localID" :name="name" :required="required" :checked="checked" :disabled="disabled">
            <span class="control__mask"></span>
            <span v-if="$slots.default" class="control__text"><slot></slot></span>
        </label>
    </div>
</template>

<script>
export default {
    name: "FormRadio",
    props: {
        auxClass: String,
        jsClass: String,
        modifiers: String,
        id: String,
        // type: {
        //     type: String,
        //     default: "text",
        // },
        name: String,
        placeholder: String,
        value: String,
        required: {
            type: Boolean,
            default: false,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        autocomplete: String,
    },
    data() {
        return {
            compClass: "form__element",
            localClass: null,
            localAuxClass: this.auxClass,
            localJsClass: this.jsClass,
            localModifiers: this.modifiers !== undefined ? this.modifiers + ",radio" : ",radio",
            localID: this.id || "radio" + (Math.floor(Math.random() * (99 - 10)) + 10),
        };
    },
    computed: {},
    setup() {
        // props, context
    },
    created() {
        this.localClass = this.compClass;

        if (this.localModifiers !== undefined) {
            this.localModifiers = this.localModifiers.replace(/^,/, '');
            this.localModifiers = this.compClass + "--" + this.localModifiers.split(",").join(" " + this.compClass + "--");
            this.localClass += " " + this.localModifiers;
        }

        if (this.localAuxClass !== undefined) {
            this.localClass += " " + this.localAuxClass;
        }

        if (this.localJsClass !== undefined) {
            this.localClass += " " + this.localJsClass;
        }
    },
    mounted() {
        //console.log('mounted');
    },
    methods: {},
};
</script>
