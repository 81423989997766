import { createApp } from "vue";
import App from "./App.vue";
import { router, currentLang, locales } from "./router";
import { createI18n } from "vue-i18n";

import { IonicVue, isPlatform, toastController, loadingController } from "@ionic/vue";
import VueVirtualScroller from 'vue-virtual-scroller';
import { setupAuth } from "./auth"; // V_01
import { Storage } from "@capacitor/storage";
import { Browser } from '@capacitor/browser';
import { store } from './store/';
// import Cookies from "js-cookie";
/* Custom styles */
import "./assets/scss/app.scss";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
//import "@ionic/vue/css/structure.css";
//import "@ionic/vue/css/typography.css";

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

/* Optional CSS utils that can be commented out */
//import "@ionic/vue/css/padding.css";
//import "@ionic/vue/css/float-elements.css";
//import "@ionic/vue/css/text-alignment.css";
//import "@ionic/vue/css/text-transformation.css";
//import "@ionic/vue/css/flex-utils.css";
//import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";



/* Server connection */
import axios from "axios";
/* Translations */
let localesMessages = {};
//console.log(locales)
locales.forEach((lang) => {
    localesMessages[lang] = require("./translations/" + lang + ".json");
});

const i18n = createI18n({
    locale: currentLang,
    fallbackLocale: process.env.VUE_APP_DEFAULT_LOCALE,
    messages: localesMessages,
});

import moment from 'moment-timezone';
// import moment from 'moment';
moment.locale(i18n.global.locale);
moment().tz('Europe/Madrid');

const axiosPublic = axios.create({
  //baseURL: 'https://pnv-cms.zorraquino.com/default/apis/general/onboard.php',
  method:'post',
  timeout: 8000,
  headers: {'Content-Type': 'application/json'}
});


var getFirstBrowserLanguage = function () {
    var nav = window.navigator,
        browserLanguagePropertyKeys = ["language", "browserLanguage", "systemLanguage", "userLanguage"],
        i,
        language;
    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {
            language = nav.languages[i];
            if (language && language.length) {
                return language;
            }
        }
    }
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            return language;
        }
    }
    return null;
};
router.beforeEach(async (to, from, next) => {
    const appLang = await Storage.get({ key: "appLang" });
    let language = to.params.lang;

    if (from.params.lang !== appLang.value) {
        language = appLang.value;
    }

    if (!language || language == "localhost") {
        let currentLang = getFirstBrowserLanguage();
        if (currentLang.indexOf("-") !== -1) currentLang = currentLang.split("-")[0];
        if (currentLang.indexOf("_") !== -1) currentLang = currentLang.split("_")[0];
        language = currentLang;
    }
    if (!language) {
        language = i18n.global.fallbackLocale;
    }
    if (!locales.includes(language)) {
        language = i18n.global.fallbackLocale;
    }
    // console.log({from}, {to});
    i18n.locale = language;
    i18n.global.locale = language;
    document.documentElement.lang = language;

    Storage.set({ key: "appLang", value: language });

    next();
});

const app = createApp(App).use(i18n).use(moment).use(IonicVue).use(VueVirtualScroller).use(router);

// app.config.globalProperties.$public_api_server = "https://pnv-cms.zorraquino.com/default/apis/general/onboard.php";
// app.config.globalProperties.$private_api_server = "https://pnv-cms.zorraquino.com/default/apis/usuario/messages.php";

app.config.globalProperties.$api_host = process.env.VUE_APP_API_HOST;
app.config.globalProperties.$app_prefix = process.env.VUE_APP_STORAGE_PREFIX;
app.config.globalProperties.$app_name = process.env.VUE_APP_NAME;
store.appName = process.env.VUE_APP_NAME;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$axiosPublic = axiosPublic;
app.config.globalProperties.$axiosPrivate = axios;
app.config.globalProperties.$logedOut = false;
app.config.globalProperties.globalToast = [];


app.mixin({
    mounted() {
        // this.enableInterceptors();
        // if ((isPlatform("ios") || isPlatform("android")) && !isPlatform("mobileweb")) {
        // if ((isPlatform("ios")) && !isPlatform("mobileweb")) {
        //     let inputsAll = document.querySelectorAll("input");
        //     let selectsAll = document.querySelectorAll("select");
        //     inputsAll.forEach(input => {
        //         input.addEventListener('focus', this.focusInput);
        //     });
        //     selectsAll.forEach(select => {
        //         select.addEventListener('focus', this.focusInput);
        //     });
        // }
    },
    methods: {
        async setStorage(key, value, isPrivate = false) {
            await Storage.set({ key: (isPrivate ? this.$app_prefix + key : key), value: JSON.stringify(value) });
        },
        async getStorage(key, isPrivate = false) {
            const item = await Storage.get({ key: (isPrivate ? this.$app_prefix + key : key) });
            return JSON.parse(item.value);
        },
        async removeStorage(key, isPrivate = false) {
            await Storage.remove({ key: (isPrivate ? this.$app_prefix + key : key) });
        },
        enableInterceptors() {
            const UNAUTHORIZED = 401;

            this.$axiosPrivate.interceptors.request.use(
                async req => {
                    // const access_token = await this.getStorage("access_token", true);
                    if (this.access_token === null) {
                        this.access_token = await this.getStorage("access_token", true);
                    }
                    req.headers['Authorization'] = `Bearer ${this.access_token}`;
                    req.headers['AppName'] = this.$app_name;
                    return req;
                },
                error => {
                    return Promise.reject(error);
                }
            );

            this.$axiosPrivate.interceptors.response.use(response => response, error => {
                const { status } = error.response;
                if (status === UNAUTHORIZED && store.isAuthenticated) {
                    if (!store.logedOut) {
                        store.logedOut = true;
                        this.logout();
                    }
                }
                return Promise.reject(error);
            });
        },

        serializeJSON(form) {
            var obj = {};
            Array.prototype.slice.call(form.elements).forEach((field) => {
                if (!field.name || field.disabled || ["file", "reset", "submit", "button"].indexOf(field.type) > -1) return;
                if (field.type === "select-multiple") {
                    var options = [];
                    Array.prototype.slice.call(field.options).forEach((option) => {
                        if (!option.selected) return;
                    });
                    if (options.length) {
                        obj[field.name] = options;
                    }
                    return;
                }
                if (["checkbox", "radio"].indexOf(field.type) > -1 && !field.checked) return;
                //obj[field.name] = field.value;
                obj[field.name] = (["checkbox", "radio"].indexOf(field.type) > -1 && field.value === "on" ? true : field.value);
            });
            //return JSON.stringify(obj, null, 2);
            return JSON.stringify(obj);
        },
        async openToastOptions(text, status) {
            this.closeToast();
            const toast = await toastController.create({
                message: text,
                position: "top",
                cssClass: "toast-dialog",
                duration: 2000,
                color: status,
                buttons: [
                    {
                        text: "✕",
                        role: "cancel",
                        handler: () => {
                            // console.log("Cancel clicked");
                        },
                    },
                ],
            });
            this.globalToast.push(toast);

            await toast.present();
            await toast.onDidDismiss();
        },
        async closeToast() {
            if (this.globalToast.length > 0) {
                this.globalToast[0].dismiss();
                this.globalToast.shift();
            }
        },
        async showTerms() {
            let browserData = { url: this.$t("terms.url") };
            if ((isPlatform("ios") || isPlatform("android")) && !isPlatform("mobileweb")) {
                browserData.windowName = '_self';
                browserData.presentationStyle = 'popover';
            }
            await Browser.open(browserData);
            // this.openModal(LegalTerms, { });
        },
        focusInput(evt) {
            let total = 0;
            let container = null;
            const _rec = (obj) => {
                total += obj.offsetTop;
                const par = obj.offsetParent;
                if (par && par.localName !== 'ion-content') {
                    _rec(par);
                } else {
                    container = par;
                }
            }

            _rec(evt.target);
            setTimeout(() => {
                container.scrollToPoint(0, total - 200, 400);
            }, 200);

        },
        // setUserLogged(userLogged) {
        //     // Cookies.set("userLogged", userLogged, { expires: 30 });
        //     Cookies.set("userLogged", JSON.stringify(userLogged));
        // },
        // deleteUserLogged() {
        //     Cookies.remove('userLogged');
        // },
        // getUserLogged() {
        //     return JSON.parse(Cookies.get("userLogged"));
        // },
        async logout(withController = true) {
            if (withController) {
                const loader = await loadingController.create({
                    message: "Cerrando sesión...",
                });
                await loader.present();
            }
            store.logedOut = false;
            await this.$auth.logout("/" + this.language + "/login");
            // loader.dismiss();
        },
    },
});

function callbackRedirect(appState) {
    router.push(appState && appState.targetUrl ? appState.targetUrl : "/");
}

router.isReady().then(() => {
    setupAuth({
        //lang: i18n.global.locale
    }, callbackRedirect)
        .then((auth) => {
            app.use(auth).mount("#app");
        });
    //app.mount("#app");
});
