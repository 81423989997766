<template>
    <ion-page>
		<Header-Start />
		<ion-content :fullscreen="true" :forceOverscroll="false">
			<div class="onboarding__page">
				<div class="onboarding__mobile">
					<swiper :pagination="true" @swiper="setSwiperInstance" @slideChangeTransitionStart="onSlideChange">
						<swiper-slide>
							<div class="onboarding__slide">
								<div class="onboarding__img tt--ab">
									<img class="ofc--img" src="../assets/img/onboarding/onboarding-1.jpg" />
								</div>
								<div class="onboarding__content">
									<h2 class="onboarding__title">{{ $t("onboardingApp.slide1title") }}</h2>
									<p class="onboarding__text">{{ $t("onboardingApp.slide1text") }}</p>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="onboarding__slide">
								<div class="onboarding__img tt--ab">
									<img class="ofc--img" src="../assets/img/onboarding/onboarding-2.jpg" />
								</div>
								<div class="onboarding__content">
									<h2 class="onboarding__title">{{ $t("onboardingApp.slide2title") }}</h2>
									<p class="onboarding__text">{{ $t("onboardingApp.slide2text") }}</p>
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="onboarding__slide">
								<div class="onboarding__img tt--ab">
									<img class="ofc--img" src="../assets/img/onboarding/onboarding-3.jpg" />
								</div>
								<div class="onboarding__content">
									<h2 class="onboarding__title">{{ $t("onboardingApp.slide3title") }}</h2>
									<p class="onboarding__text">{{ $t("onboardingApp.slide3text") }}</p>
								</div>
							</div>
						</swiper-slide>
					</swiper>
					<div class="onboarding__control">
						<div class="slide__dots">
							<div id="dot0" class="dot active"></div>
							<div id="dot1" class="dot"></div>
							<div id="dot2" class="dot"></div>
						</div>
						<div class="wrp">
							<ion-button modifiers="" color="primary" expand="block" class="ion-btn" @click.prevent="this.slideNext()">{{$t("onboardingApp.siguiente")}}</ion-button>
						</div>
					</div>
				</div>
				<div class="onboarding__desktop">
					<div class="onboarding__top flex">
						<div class="col--1-2">
							<div class="mask">
								<div class="onboarding__img tt--ab">
									<img class="ofc--img" src="../assets/img/onboarding/onboarding-4.jpg" />
								</div>
							</div>
						</div>
						<div class="col--1-2 flex align--center">
							<div class="onboarding__desktop__text">
								<div class="onboarding__content">
									<div class="onboarding__logo">
										<img src="../assets/img/logo-zukesan.svg" />
									</div>
									<h2 class="onboarding__title">{{ $t("onboardingApp.slide4title") }}</h2>
									<p class="onboarding__text">{{ $t("onboardingApp.slide4text") }}</p>
								</div>
								<router-link class="btn btn--base" v-bind:to="'/' + this.language + '/login'">
									{{ $t("forms.login.cta") }}
								</router-link>
								<router-link class="btn btn--secondary" v-bind:to="'/' + this.language + '/registration'">
									{{ $t("forms.goto.register.cta") }}
								</router-link>
							</div>
						</div>
					</div>
					<Sdfooter/>
				</div>
			</div>
		</ion-content>
    </ion-page>
</template>
<script>
import { useIonRouter, IonContent, IonPage, IonButton } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import HeaderStart from "@/components/HeaderStart.vue";
import Sdfooter from "@/components/footer.vue";
import "swiper/css/pagination";
export default defineComponent({
    name: "OnboardingAppItem",
    components: {
        IonContent,
        IonPage,
        IonButton,
        Swiper,
        SwiperSlide,
        HeaderStart,
		Sdfooter,
    },
    props: [],
    data() {
        return {
            lang: "",
        };
    },
    setup() {
        const ionRouter = useIonRouter();
        const slides = ref();
        const setSwiperInstance = (swiper) => {
            slides.value = swiper;
        };
        const onSlideChange = (e) => {
            document.querySelectorAll(".dot").forEach((e) => e.classList.remove("active"));
            document.querySelector("#dot" + e.activeIndex).classList.add("active");
        };

        return { ionRouter, setSwiperInstance, slides, onSlideChange };
    },
    created() {
        console.log(this.slides);
        //const onboardingAppViewed = this.getStorage("onboardingAppViewed");
    },
    computed: {
        language() {
            return this.$i18n.locale || document.documentElement.lang;
        },
    },
    watch() {
        const lang = this.$i18n.locale;
        this.$el.querySelector(".lang__item." + lang).classList.add("active");
    },
    mounted() {
        const lang = this.$i18n.locale;
        this.$el.querySelector(".lang__item." + lang).classList.add("active");
    },
    methods: {
        next() {
            this.setStorage("onboardingAppViewed", true);
            this.setStorage("onboardingAppViewedNow", true);
            console.log(this.language)

            if (this.$auth.isAuthenticated) {
                // this.$router.push("/" + this.language + "/onboardinguser");
                this.ionRouter.navigate(`/${this.language}/onboardinguser`, 'root', 'replace');
            } else {
                // this.$router.push("/" + this.language + "/login");
                this.ionRouter.navigate(`/${this.language}/login`, 'root', 'replace');
            }

        },
        slideNext() {

            if (this.slides.activeIndex < 2) {
                this.slides.slideNext();
            } else {
                this.next();
            }
        },
        languageSwitch() {
            if (this.$el.querySelector(".onboarding__lang").classList.contains("l-open")) {
                this.$el.querySelector(".onboarding__lang").classList.remove("l-open");
            } else {
                this.$el.querySelector(".onboarding__lang").classList.add("l-open");
            }
        },
    },
});
</script>
